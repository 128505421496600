import { useState, useEffect } from 'react';
import { getLocPhysicalAndCity as getValueTrackingParams } from '../helpers/adRefTracking';

export const useValueTrackPageSwap = ({ data }) => {
	return (
		data &&
		data.allContentfulPage.edges[0] &&
		data.allContentfulPage.edges[0].node
	);
};
