import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroArea from '../components/hero-area';
import CalloutBar from '../components/callout-bar';
import Card from '../components/card';
import styled from 'styled-components';
import FeaturedItems from '../components/featured-items';
import Reputation from '../components/reputation';
import PhotoGalleryComponent from '../components/photo-gallery-component';
import Button from '../components/button';
import CTA from '../components/cta-bar';
import 'react-photoswipe/lib/photoswipe.css';
import { useTextReplacement } from '../hooks/useTextReplacement';
import { useValueTrackPageSwap } from '../hooks/useValueTrackPageSwap';
import SocialReviewsFullwidth from '../components/social-reviews-fullwidth';
import ReviewTilesComponent from '../components/ReviewTilesComponent';



const GalleryHomePage = styled.div`
	padding: 2.5em 0;
	background: white;
	text-align: center;
	h2 {
		margin-bottom: 0.35em;
		font-size: calc(0.75vw + 1.2em);
	}
	p {
		max-width: 767px;
		color: #555;
		margin: 0 auto;
		margin-bottom: 1.5em;
	}
	img:nth-child(15) {
		display: none;
	}
`;

const IndexPage = props => {
	let homePageNode = useValueTrackPageSwap(props);

	const { testimonials: testimonial_video_posts } = (homePageNode &&
		homePageNode.testimonialSection &&
		homePageNode.testimonialSection) || { testimonials: [] };

	const SidebarColumn = () => {
		const sideBarCards = homePageNode.sidebar.map((sidebar, index) => (
			<Card key={index} sidebar={sidebar} />
		));

		return sideBarCards;
	};

	console.log('homePageNode', props);
	return (
		<Layout
			pageProps={props}
			footerLogoShowcase={
				homePageNode &&
				homePageNode.footerLogoShowcase &&
				homePageNode.footerLogoShowcase
			}
			isHomePage={true}
			headerTransparent={false}
			headerShadow={false}
		>
			<SEO
				title={homePageNode.metaTitle}
				description={homePageNode.metaDescription}
				keywords={
					homePageNode.metaKeywordsList
						? homePageNode.metaKeywordsList
						: []
				}
			/>
			{homePageNode.heroAreaCarousel || homePageNode.heroArea ? (
				<HeroArea
					heroContent={
						homePageNode.heroAreaCarousel
							? homePageNode.heroAreaCarousel
							: homePageNode.heroArea
					}
				/>
			) : null}
			{homePageNode.featuredItems ? (
				<FeaturedItems data={homePageNode.featuredItems} />
			) : (
				''
			)}
				<ReviewTilesComponent />

			<div className="bg-gradient-to-b from-gray-50 to-white">
				{homePageNode &&
				homePageNode.photoGallery &&
				homePageNode.photoGallery[0].photos ? (
					<PhotoGalleryComponent
						gallery={homePageNode.photoGallery}
					/>
				) : null}
			</div>

			<SocialReviewsFullwidth modalLinkTo={`/reviews/all/`} />
			<section className="pt-10">
				<div className="container">
					<div className="row">
						<div
							className={
								homePageNode.sidebar
									? 'col-12 is-service-page'
									: 'col-12 is-service-page'
							}
						>
							{homePageNode &&
							homePageNode.featuredContent &&
							homePageNode.featuredContent.featuredContent ? (
								<div
									className="prose md:prose-lg lg:prose-xl"
									dangerouslySetInnerHTML={{
										__html: homePageNode.featuredContent
											.featuredContent
									}}
								/>
							) : (
								''
							)}
						</div>
					</div>
				</div>
			</section>
			{/* <TestimonialSection testimonial_video_posts={testimonial_video_posts} /> */}

			<div className="bg-gray-50 py-12">
				<div className="flex flex-wrap  px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
					<div
						className={`${
							homePageNode.sidebar ? 'md:w-2/3 md:pr-4' : 'w-full'
						} prose md:prose-lg  `}
						dangerouslySetInnerHTML={{
							__html: homePageNode.body.body
						}}
					/>

					{homePageNode.sidebar ? (
						<div className="w-full md:w-1/3 md:pl-4 space-y-4 md:space-y-6">
							<SidebarColumn />
						</div>
					) : (
						''
					)}
				</div>
			</div>

			<CTA
				title={
					'Start Your ' +
					homePageNode.serviceTitle +
					' Project Today!'
				}
				customTitle={homePageNode.footerCallToActionHeading}
				customDesc={homePageNode.footerCallToActionDesc}
				bgimage={homePageNode.footerCallToActionImg}
				serviceHeroImage={homePageNode.heroImage}
				btn={'Get Started!'}
				props={props}
			/>
		</Layout>
	);
};

export default IndexPage;

export const pageQuery = graphql`
	fragment pageContent on ContentfulPageConnection {
		edges {
			node {
				featuredItems {
					id
					serviceTitle
					featuredLabel
					excerpt
					shortExcerpt
					slug
					heroImage {
						gatsbyImageData(
							width: 370
							quality: 70
							placeholder: NONE
						)
					}
				}
				id
				slug
				pageTitle
				metaTitle
				metaKeywordsList
				metaRobotsContent
				metaDescription
				featuredContent {
					featuredContent
				}
				body {
					body
				}
				footerLogoShowcase

				footerCallToActionHeading
				footerCallToActionDesc
				footerCallToActionImg {
					gatsbyImageData(width: 1400, placeholder: NONE)
				}
				photoGallery {
					id
					title
					description {
						description
					}
					photos {
						id
						title
						gatsbyImageData(
							width: 150
							quality: 70
							aspectRatio: 1.26153846154
							placeholder: NONE
						)
						fullSize: gatsbyImageData(
							width: 1874
							quality: 70
							placeholder: NONE
						)
					}
				}
				heroArea {
					heroTitle
					description {
						description
					}
					primaryActionBtnLabel
					primaryActionBtnLink
					heroImage {
						gatsbyImageData(
							width: 1600
							quality: 70
							placeholder: BLURRED
						)
					}
				}
				heroAreaCarousel {
					heroTitle
					description {
						description
					}
					primaryActionBtnLabel
					primaryActionBtnLink
					heroImage {
						gatsbyImageData(
							width: 1600
							quality: 70
							placeholder: BLURRED
						)
					}
				}
				sidebar {
					id
					title
					content {
						id
						content
					}
					cssClasses
				}
				testimonialSection {
					testimonials {
						id
						title
						projectReference {
							slug
						}
						video {
							id
							title
							file {
								url
								contentType
							}
						}
						thumbnail {
							gatsbyImageData(
								width: 600
								quality: 90
								placeholder: NONE
							)
						}
					}
				}
			}
		}
	}

	{
		allContentfulPage(filter: { slug: { eq: "home" } }) {
			...pageContent
		}
		campaignPages: allContentfulPage(
			filter: { slug: { glob: "home-*" } }
			sort: { order: ASC, fields: createdAt }
		) {
			...pageContent
		}
	}
`;
